.modal-form {
  .ant-modal-body {
    padding: 0;
    margin: 0;
  }
  .modal-header {
    height: 40px;
    line-height: 40px;
    background-color: #2b579a;
    color: #fff;
    font-weight: 500;
    text-align: center;
  }
  .modal-body {
    padding: 24px 24px;
  }
}

@primary-color: #2b579a;