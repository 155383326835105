/* 布局框架 */
/* 布局主题
---------------------------------------------------------------- */
.layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #e6e6e6;
}
.layout .layout_header {
  height: 170px;
  width: 100%;
  margin-bottom: 10px;
}
.layout .layout_footer {
  width: 100%;
  height: 30px;
}
.layout .layout_content {
  flex: 1;
  width: 100%;
  overflow: auto;
  padding: 0 20px;
  margin-bottom: 10px;
}
/* 响应式
---------------------------------------------------------------- */
@media screen and (max-width: 1200px) {
  .layout {
    width: 1200px;
  }
}
@media screen and (max-height: 900px) {
  .layout {
    height: 900px;
  }
}
