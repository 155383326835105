/* 全局样式 */
/* 盒子方位间距
---------------------------------------------------------------- */
.margin-clear {
  margin: 0;
}
.margin-b-30 {
  margin-bottom: 30px;
}
.padding-5-0 {
  padding: 5px 0;
}
.padding-b-10 {
  padding: 10px;
}
/* 自定义文本对齐
---------------------------------------------------------------- */
/* 统一label文本两端对齐 */
.Justified {
  display: inline-block;
  text-align: justify;
  text-align-last: justify;
}
/* 统一文本右对齐 */
.text-right {
  display: inline-block;
  text-align: right;
}
/* 自定义宽度
---------------------------------------------------------------- */
.width-percent {
  width: 100% !important;
}
.width-385 {
  width: 385px !important;
}
.width-200 {
  width: 200px;
}
.width-60 {
  width: 60px;
}
.width-30 {
  width: 30px;
}
.width-42 {
  width: 42px;
}
/* 4个字符宽度(带星号) */
.width-56 {
  width: 56px;
}
.width-67 {
  width: 67px;
}
.width-70 {
  width: 70px !important;
}
.width-71 {
  width: 71px;
}
.width-78 {
  width: 78px;
}
.width-80 {
  width: 80px;
}
.width-85 {
  width: 85px;
}
.width-89 {
  width: 89px;
}
.width-98 {
  width: 98px;
}
.width-120 {
  width: 120px;
}
.width-140 {
  width: 140px;
}
.width-174 {
  width: 174px !important;
}
.width-243 {
  width: 242.61px !important;
}
/* 标签高度 */
.height-percent {
  height: 100% !important;
}
/* 消除方位间距 */
.rm-padding-b-24 .ant-card-body {
  padding-bottom: 0;
}
/* 标签位置 */
.text-center {
  text-align: center;
}
/* 自定义颜色
---------------------------------------------------------------- */
.theme-color,
.color-blue {
  color: #2b579a;
}
.color-green {
  color: #52c41a !important;
}
.color-red {
  color: #ff4d4f !important;
}
.color-alert {
  color: #faad14 !important;
}
.color-gray-7 {
  color: #8c8c8c;
}
/* 鼠标停留放大标签动画 */
.action button span:hover {
  transform: scale(2);
}
/* 标记
---------------------------------------------------------------- */
.sign::before {
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.sign::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.sign-no::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
/* 单位 */
.unit-1 {
  padding: 4px 0 0 8px;
}
/* 字体大小 */
.font-16 {
  font-size: 16px;
}
.font-22 {
  font-size: 22px;
}
/* 选中行高亮背景 R:235 G:235 B:235*/
.row-bg {
  background: rgba(235, 235, 235, 0.5) !important;
}
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody .ant-table-row-hover,
.ant-table-tbody .ant-table-row-hover > td {
  background: #b2d1fa !important;
}
.ant-table-fixed .ant-table-row-hover,
.ant-table-fixed .ant-table-row-hover > td {
  background: #9ec4f5 !important;
}
/* 边框线 */
.table-border {
  padding-left: 8px;
}
.table-border div {
  border: 1px solid #000;
  margin: 0px -1px -1px 0px;
  text-align: center;
}
/* 标签统一水平线上 */
.level-x {
  display: flex;
  align-items: center;
}
/* 表单 item 样式（无标记：*）
---------------------------------------------------------------- */
.item-sign-no {
  display: flex;
  align-items: center;
}
.item-sign-no .item-label {
  display: inline-block;
  text-align: right;
}
.item-sign-no .item-label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.item-sign-no .item-input {
  flex: 1;
}
/* 打印
---------------------------------------------------------------- */
.print {
  padding: 24px 36px !important;
}
/* 伸缩布局
---------------------------------------------------------------- */
/* flex 布局 */
.g_f {
  display: flex;
}
/* x 轴对齐 */
.g_fx {
  display: flex;
}
/* x 轴：右对齐 */
.g_fx_r {
  display: flex;
  justify-content: flex-end;
}
/* x 轴左右对齐 */
.g_fx_lr {
  display: flex;
  justify-content: space-between;
}
/* x 轴上垂直居中左对齐 */
.g_fx_m {
  display: flex;
  align-items: center;
}
/* x 轴上垂直居中右对齐 */
.g_fx_mr {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
/* x 轴上底部对齐 */
.g_fx_b {
  display: flex;
  align-items: flex-end;
}
/* y 轴对齐 */
.g_fy {
  display: flex;
  flex-direction: column;
}
.g_fy_m {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* x 轴上平均分配宽度 */
.g_fx_a {
  flex: 1;
  overflow: hidden;
}
/* 盒子间距
---------------------------------------------------------------- */
.g_ml_10 {
  margin-left: 10px;
}
.g_ml_20 {
  margin-left: 20px;
}
.g_ml_40 {
  margin-left: 40px;
}
.g_mr_10 {
  margin-right: 10px;
}
.g_mr_20 {
  margin-right: 20px;
}
.g_mb_10 {
  margin-bottom: 10px;
}
.g_mb_20 {
  margin-bottom: 20px;
}
.g_mb_30 {
  margin-bottom: 30px;
}
.g_pt_4 {
  padding-top: 4px;
}
.g_pr_10 {
  padding-right: 10px;
}
.g_pb_10 {
  padding-bottom: 10px;
}
/* 盒子圆角
---------------------------------------------------------------- */
.g_shape {
  border-radius: 15px;
  border: 1px solid transparent;
}
.g_shape_login {
  border: 1px solid #d9d9d9;
}
/* 容器各种宽度、高度
---------------------------------------------------------------- */
.g_w_percent {
  width: 100%;
}
.g_h_percent {
  height: 100%;
}
.g_w_42 {
  width: 42px;
}
.g_w_56 {
  width: 56px;
}
.g_w_60 {
  width: 60px;
}
.g_w_67 {
  width: 67px;
}
.g_w_70 {
  width: 70px;
}
.g_w_71 {
  width: 71px;
}
.g_w_78 {
  width: 78px;
}
.g_w_80 {
  width: 80px;
}
.g_w_85 {
  width: 85px;
}
.g_w_89 {
  width: 89px;
}
.g_w_98 {
  width: 98px;
}
.g_w_120 {
  width: 120px;
}
.g_w_140 {
  width: 140px;
}
.g_w_174 {
  width: 174px !important;
}
.g_w_243 {
  width: 243px;
}
.g_w_385 {
  width: 385px;
}
.g_minw_705 {
  min-width: 705px;
}
/* 组件隐藏切换
---------------------------------------------------------------- */
/* 左侧展开 / 折叠 */
.g_switch {
  background-color: #e3edf5;
  padding: 20px 0;
  font-size: 10px;
}
.g_switch:hover {
  background-color: #fc6;
  cursor: pointer;
}
/* 鼠标停留放大标签动画
---------------------------------------------------------------- */
.g_action button span:hover {
  transform: scale(2);
}
/* 选中行高亮背景
---------------------------------------------------------------- */
.g_row_bg {
  background: #9ec4f5 !important;
}
/* 自定义文本对齐
---------------------------------------------------------------- */
/* 统一文本右对齐 */
.g_txt_r {
  display: inline-block;
  text-align: right;
}
/* 标签位置 */
.g_txt_c {
  text-align: center;
}
/* 自定义颜色
---------------------------------------------------------------- */
.g_theme_color,
.g_color_blue {
  color: #2b579a;
}
.g_color_green {
  color: #52c41a !important;
}
.g_color_red {
  color: #ff4d4f !important;
}
.g_color_alert {
  color: #faad14 !important;
}
.g_color_gray {
  color: #8c8c8c;
}
.g_warning_red {
  color: #cc0000 !important;
}
.g_warning_blue {
  color: #4609f0 !important;
}
/* 标记
---------------------------------------------------------------- */
.g_sign_no::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
/* 盒子的显示与隐藏
---------------------------------------------------------------- */
.g_hide {
  display: none;
}
.g_display {
  display: block;
}
/* antd 组件样式
---------------------------------------------------------------- */
.ant-btn,
.ant-select-selector,
.ant-input,
.ant-picker,
.ant-pagination-options-quick-jumper input,
.ant-card {
  border-radius: 4px !important;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 4px 4px 0 0 !important;
}
