/* 性别比例图 */
.bi_genderratio {
  width: 100%;
  height: 25px;
  color: #fff;
}
.bi_genderratio .bi_genderratio_label {
  text-align: center;
  height: 100%;
  line-height: 23px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 'bold';
}
