.login {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 1647px;
  min-height: 880px;
  width: 100%;
  height: 100%;
  background-image: url('./images/login.png');
  background-size: 100% 100%;
  .login_header {
    img {
      width: 70%;
      height: 100%;
      position: relative;
      top: 300px;
      right: 350px;
    }
  }
  .login_content {
    width: 400px;
    height: 260px;
    padding: 20px 60px;
    position: relative;
    bottom: 50px;
    left: 350px;
    background: rgba(255, 255, 255, 1);
    h2 {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
      color: rgba(51, 102, 153, 1);
    }
  }
  .login_footer {
    position: relative;
    right: 320px;
    font-size: 23px;
    margin-bottom: 70px;
    color: rgba(255, 255, 255, 0.6);
  }
}

@primary-color: #2b579a;