@font-face {font-family: "iconfont";
  src: url('iconfont.eot?t=1618386531436'); /* IE9 */
  src: url('iconfont.eot?t=1618386531436#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('data:application/x-font-woff2;charset=utf-8;base64,d09GMgABAAAAAANAAAsAAAAABzAAAALyAAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHEIGVgCDBgqCAIIFATYCJAMMCwgABCAFhG0HTRtiBsgOJSHBwNDtokhQPHzt9zt3dz9qSTXSPZFIqokhE6rYdEKjBBohEelawrtX93o/CCVUiUJM/idHMT5ZZZ6BccpUoM0wtWsnwovDaWMJzrcsl7kGdVEXYBxQQGNtWgQlEog3jF21VB7SbgJNBq3kW6uoa4MwBR4UiCsmD0NYQa105IYGoa5hZhEvgjWk2/QOeFbfj38pEUZSy/DIjctyPxR9v/n0P2+rtukTCJzljDDnkTEPFOKw0bwtMAnPC9TkC4OCUlCnCGkpX1Wh2IG07qB/vETUwdktYBpGEt9vGI3g+/8sCWRQ5zOi68ANkvdMXSHX9UdFdUi3nINeYqfbMcXn8vVOuxkP7Vn2ZxtM+rTtWnPs/VtszJ/nOO2e1+m6bc+deWftshfu/AseXrXbXHf54amNzK7EQzt74ouWtNCV4v/1J1ZfknoSuvYP1M0rLMr/9o0JChNGoR10vaY2Vegx8THXUQsYzunPAv/8yc8IQzB5WZZwYqOqMiSYEZqbCcEhifP/r9hS1hNIGKJwxXjh1NZd2AmQARnJlEIYAqje5DWBGQr5+Bc+BB8bExcMxub9Doss4Ovk+BlwVW8KDNdquDNL8Kc5A6tKwMOaXDw2Y0fWbPqujQnItGwqwPYuH6dLewcHCQ0FjglJvQHIGowjC3YeapotQJ0Gi9BkTuX8Zt24QFEaMWs3gNDhDiRt3iHr8B5ZsB+gps9nqNMRgdBkMyau2GwyWEQpx2GBfjKynjDJ0CnViYg4twXl1eowz/MUsQO5xXwkOSGpHKtEHfkSY6w1cooQlFBuaKQC7IeqahCTG2MoiYRRIcycxETa9KAEydAgwioODROQHzFiPYKRGHRU1BciKp9vgWSrqYbxjoo+fQfEWVjzRLIESQOQlWJ9UMWj3GNZQ5ZCECiC4gwaogJ0QyqVgTCbu41BEiHB6ISgKUciqUWHihO2l2r/twGa4DfkSJGj6Lk2BKNMGhbM0FdRpqpMl6NWW8hzKgAAAA==') format('woff2'),
  url('iconfont.woff?t=1618386531436') format('woff'),
  url('iconfont.ttf?t=1618386531436') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
  url('iconfont.svg?t=1618386531436#iconfont') format('svg'); /* iOS 4.1- */
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-notification-filling:before {
  content: "\e7ca";
}

.icon-user-filling:before {
  content: "\e7f9";
}

