.header {
  position: relative;
  background: #2b579a;
  .header_up {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    .up_l {
      display: flex;
      align-items: center;
      img {
        width: 40px;
        height: 52.6px;
      }
      h1 {
        color: white;
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 2px;
        margin-left: 20px;
        margin-bottom: 0;
      }
    }
    .up_r {
      display: flex;
      align-items: center;
    }
  }

  .header_menu1 {
    height: 34px;
    margin: 0;
    padding: 0 30px;
    .header_menu1_item {
      padding: 5px 10px;
      color: #fff;
      border-radius: 4px 4px 0 0;
      border: 1px solid transparent;
      cursor: pointer;
    }
    .header_menu1_active {
      background: #fff;
      color: #2b579a;
    }
  }
  .header_menu2 {
    width: 100%;
    height: 60px;
    margin: 0;
    padding: 0 30px;
    background: #fff;
    border-bottom: 1px solid #ccc;
    .header_menu2_item {
      padding: 10px 20px;
      align-items: center;
      .header_menu2_item_icon {
        width: 24px;
        height: 24px;
      }
      .header_menu2_item_content {
        color: #666;
      }

      .header_menu2_active {
        color: #2b579a;
        .header_menu2_item_content {
          color: #2b579a;
        }
      }
    }
  }
}


@primary-color: #2b579a;