/* 数据可视化 BI 看板 样式 */
/* BI 模块布局
---------------------------------------------------------------- */
.bi {
  width: 1920px;
  height: 1080px;
  padding: 30px 0;
  background-image: url('./images/bigScreen-bg.png');
  background-size: 100% 100%;
  font-family: Source Han Sans CN;
  color: #fff;
  /* 头部 */
  /* 主体内容 */
}
.bi .bi_header {
  width: 100%;
  height: 75px;
  padding: 0 30px;
  background-image: url('./images/header-border.png');
  background-size: 100% 100%;
}
.bi .bi_header .bi_header_section1 {
  padding-top: 20px;
}
.bi .bi_header .bi_header_section1 .bhs1_label {
  font-size: 22px;
  font-weight: 500;
}
.bi .bi_header .bi_header_section1 .bhs1_txt {
  font-size: 44px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #0bf4fb;
}
.bi .bi_header .bi_header_section2 {
  margin: 0;
  font-size: 38px;
  font-weight: bold;
  color: #010d3b;
  background: linear-gradient(0deg, #3caff1 18.48144531%, #d0fdfb 80.20019531%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-left: 20px;
  text-align: center;
  cursor: pointer;
}
.bi .bi_header .bi_header_section3 {
  padding-top: 20px;
  height: 100%;
}
.bi .bi_main {
  width: 100%;
  padding: 20px 30px;
}
.bi .bi_main .bi_main_img {
  width: 449px;
}
/* BI 模块公共样式
---------------------------------------------------------------- */
/* 图标标题 */
.bi_chart {
  width: 100%;
  margin-bottom: 18px;
  /* 图表标题 */
  /* 图表背景 */
}
.bi_chart .bi_chart_label {
  width: 298px;
  height: 35px;
  line-height: 35px;
  margin-bottom: 8px;
  padding-left: 12px;
  background: url('./images/tag-bg.png');
  background-size: 100% 100%;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 2px;
  color: #fff;
}
.bi_chart .bi_chart_bg {
  background: rgba(14, 138, 164, 0.25);
}
.bi_select {
  width: 178px;
  height: 36px;
  padding-left: 12px;
  background: rgba(14, 138, 164, 0.25);
  border: 0;
  outline: none;
  font-size: 16px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
}
.bi_select .bi_select_item {
  background: #0a2d6a;
}
/* BI 图表样式
---------------------------------------------------------------- */
/* 科室患者分布 */
.bi_chart1 {
  width: 450px;
  height: 175px;
  padding: 10px 10px;
}
.bi_chart1 .bi_chart1_title {
  color: #1be1d3;
  border-bottom: 1px solid #65eedf;
}
.bi_chart1 .bi_chart1_body .bi_chart1_body_label {
  font-size: 16px;
}
.bi_chart1 .bi_chart1_body .bi_chart1_body_content .bcbc_bar {
  display: inline-block;
  max-width: 160px;
  height: 5.5px;
  background: #34a0fc;
}
.bi_chart1 .bi_chart1_body .bi_chart1_body_content .bcbc_count {
  color: #DDAD61;
}
/* 年龄分布 */
.bi_chart2 {
  width: 450px;
  height: 213px;
}
/* 今日数据 */
.bi_chart3 {
  width: 810px;
  height: 220px;
}
.bi_chart3 .bi_chart3_item {
  width: 100%;
  height: 100%;
}
/* 血糖监测点分布、血糖达标率 */
.bi_chart4 {
  width: 400px;
  height: 258px;
}
/* 全院患者变化趋势 */
.b1_chart5 {
  width: 100%;
  height: 250px;
}
